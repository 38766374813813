.main-div{
  overflow-x: hidden;
  background-color: #000;
  padding-top: 2rem;
}
.main-div_2{
  overflow-x: hidden;
  background-color: #000;
  padding: 1rem 0 2rem 0;
}
article {
  display: flex;
  width: 200% 100%;
  animation: bannermove 20s linear infinite;

  
}
.article_two{
  display: flex;
  width: 200% 100%;
  animation: bannermoveTwo 20s reverse linear infinite;
}

article.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}z

div {
  width: 100%;
}

ul {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}


.SliderImgs {
  border: 2px solid #FEE715FF;
}

li {
  width: 100%;
  background: transparent;
  margin: 0 1rem;
  padding: 0;

}





@keyframes bannermove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
/* ----------------------------------- */
@keyframes bannermoveTwo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}