.root {
    background-color: #000;
    font-family: 'Century Gothic', sans-serif;
                                                
    padding-top: 5rem;
}
.mainBox{
    background-color: blue;
}
.percent{
    color: rgb(0, 0, 0);
    

}

.vertical-timeline-element-icon{
    background: rgb(254, 231, 21);
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.title {
    /* color: rgb(255, 255, 255); */
    color: #fff;
    text-align: center;
    margin: 2rem 0rem;
    font-size: 3rem;
    letter-spacing: 2px;
}

.vertical-timeline-element-content{
    background:#FEE715FF !important;
    box-shadow:0 3px 0 #676767 !important;
    color: #000;
    font-weight: bold;
}
.element-title{
    color: #000 !important;


}

.vertical-timeline-element-content-arrow{
    color:#ff781f !important;
}