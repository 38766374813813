

.facebook:hover{
    color: blue;
    
}



.instagram:hover{

    color: rgb(212, 0, 255);
    
}



.twitter:hover{
    color: rgb(47, 186, 241);
}
